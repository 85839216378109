.mfa__team {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
  border: 2px solid var(--color-main);
  box-shadow: 0px 8px 15px rgba(58, 224, 128, 0.3);
  border-radius: 5px;
}

.mfa__team-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mfa__team-content p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  color: var(--color-text);
}

.mfa__team-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 35px;
  line-height: 45px;
  margin: 1rem 0;
  color: #fff;
  text-align: center;
}

.mfa__team-content h3 {
  font-family: var(--font-family);
  font-weight: lighter;
  font-size: 1.5em;
  line-height: 0.7em;
  color: var(--color-main);
  margin-bottom: 2rem;
  text-align: center;
}

.mfa__team-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.mfa__team-btn button {
  background: var(--color-main);
  border-radius: 40px;
  color: #000;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
}

.mfa__team-photo {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: 1rem;
}

.mfa__team-photo img {
  width: 75%;
  height: 75%;
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .mfa__team {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .mfa__team {
    flex-direction: column;
  }

  .mfa__team-btn {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 550px) {
  .mfa__team {
    margin: 4rem 2rem;
  }

  .mfa__team-content h3 {
    font-size: 15px;
    line-height: 32px;
  }

  .mfa__team-content h1 {
    font-size: 27px;
    line-height: 25px;
  }

  .mfa__team-content p {
    font-size: 13px;
    line-height: 21px;
  }

  .mfa__team-btn button {
    font-size: 14px;
    line-height: 28px;
  }
}

@media screen and (max-width: 550px) {
  .mfa__team {
    margin: 1rem 1rem;
  }

  .mfa__team-content h3 {
    font-size: 13px;
    line-height: 29px;
  }

  .mfa__team-content h1 {
    font-size: 25px;
    line-height: 22px;
  }

  .mfa__team-content p {
    font-size: 11px;
    line-height: 18px;
  }

  .mfa__team-btn button {
    font-size: 12px;
    line-height: 25px;
  }
}
