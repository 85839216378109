@mixin shrinkLabel {
  top: -14px;
  font-size: 12px;
  color: #3ae080;
}

.group {
  position: relative;
  margin: 45px 0;

  .form-input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    background: none;
    border-radius: 5px 0px 0px 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);

    &:focus {
      outline: none;
    }

    &:focus ~ .form-input-label {
      @include shrinkLabel();
    }
  }

  // input[type="email"] {
  //   letter-spacing: 0.3em;
  // }

  .form-input-label {
    color: grey;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 7px;
    top: 16px;
    transition: 300ms ease all;

    &.shrink {
      @include shrinkLabel();
    }
  }
}
