.mfa__features {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mfa__features-heading {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  text-align: left;
  margin-bottom: 3rem;
}

.mfa__features-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
}

.mfa__features-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-text);
  margin-top: 1rem;
  max-width: 700px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 23px;
  }

  @media screen and (max-width: 425px) {
    font-size: 15px;
    line-height: 20px;
  }
}

.mfa__features-container {
  display: grid;
  width: 100%;
  justify-content: flex-start;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 1024px) {
    column-gap: 20px;
  }
  @media screen and (max-width: 768px) {
    column-gap: 1px;
  }
}

.mfa__features-image-container {
  display: flex;
}

.mfa__features-heading-image-container {
  padding-left: 3rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}

.mfa__features-heading-image-parent {
  max-width: 750px;
}

@media screen and (max-width: 990px) {
  .mfa__features {
    flex-direction: column;
  }

  .mfa__features-heading {
    margin: 0 0 2rem 0;
  }
}

@media screen and (max-width: 550px) {
  .mfa__features-heading h1 {
    font-size: 28px;
    line-height: 38px;
  }
  .mfa__features-container {
    display: flex;
    flex-direction: column;
  }

  .mfa__features-container__feature {
    flex-direction: column;
  }
}

@media screen and (max-width: 425px) {
  .mfa__features-heading {
    flex-direction: column;
  }
  .mfa__features-heading-image-container {
    padding-left: 0;
    padding-top: 2rem;
  }



  .mfa__features-container_feature-text p {
    font-size: 15px;
    line-height: 20px;
  }

  .mfa__features-container__feature {
    margin: 2rem 0;
  }
}
