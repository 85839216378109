.mfa__scrollToTop-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mfa__scrollToTop-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 70px;
  font-size: 27px;
  background: var(--color-main);
  color: #000;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 8px 15px rgba(58, 224, 128, 0.3);

  @media screen and (max-width: 768px) {
    bottom: 20px;
    right: unset;
  }
}
