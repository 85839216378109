.mfa__Sizing-title {
  color: white;
  text-align: center;
}

.mfa__Sizing-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mfa__Sizing-container img {
  display: flex;
  max-width: 1000px;

  @media screen and (max-width: 1440px) {
    max-width: 800px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 600px;
  }

  @media screen and (max-width: 768px) {
    max-width: 400px;
  }

  @media screen and (max-width: 425px) {
    max-width: 300px;
  }

  @media screen and (max-width: 375px) {
    max-width: 200px;
  }
}
