.mfa__Contact-container {
  color: white;
  display: flex;
  justify-content: center;
}

.mfa__Contact-text-group {
  margin: 15px;
  h3,
  p {
    color: white;
  }
  ul {
    padding-left: 1rem;
  }
}

.mfa__Contact-title {
  text-align: center;
  margin-bottom: 1rem;
}

.formContact-email-text {
  color: #fff;
  padding-left: 5px;
  cursor: pointer;

  &:hover {
    color: #3ae080;
  }
}
