.mfa__features-container__feature {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 2.5rem 1rem 1rem 1rem;
}

.mfa__features-container__feature-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mfa__features-container__feature-title h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  max-width: 500px;
  color: white;
}

.mfa__features-container__feature-title div {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.95);
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
}

.mfa__features-container__feature-title i {
  color: #3ae080;
  padding: 0 0 1rem 0;
}

.mfa__features-container_feature-text {
  flex: 2;
  max-width: 500px;
  display: flex;
  margin: 1rem 0;
}

.mfa__features-container_feature-text p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-text);
}

.mfa__features-container_feature-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}

@media screen and (max-width: 550px) {
  .mfa__features-container__feature-title h1 {
    font-size: 14px;
    line-height: 22px;
  }

  .mfa__features-container__feature-title p {
    font-size: 12px;
    line-height: 20px;
  }

  .mfa__features-container__feature {
    margin: 1rem 0;
  }
}
