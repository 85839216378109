.mfa__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 7rem;
}

.mfa__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mfa__navbar-links_container p {
  transition: all 0.3s ease-in-out;
}

.mfa__navbar-links_container p:hover {
  transform: scale(1.03);
}

.mfa__navbar-links_logo {
  margin-right: 2rem;

  img {
    width: 260px;
    height: 70px;
  }
}

.mfa__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.mfa__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mfa__navbar-links_container p,
.mfa__navbar-sign p,
.mfa__navbar-menu_container p {
  color: white;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  letter-spacing: 0.2px;
  margin: 0 1rem;
  cursor: pointer;
  z-index: 5;
}

.mfa__navbar-sign button,
.mfa__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #000;
  background: #3ae080;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.mfa__navbar-sign button {
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.03);
  }
}

.mfa__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.mfa__navbar-menu svg {
  cursor: pointer;
}

.mfa__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: var(--color-bg);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

.mfa__navbar-menu_container p {
  margin: 1rem 0;
}

.mfa__navbar-menu_container-links-sign {
  display: none;
}

.mfa__navbar-links_container a {
  font-size: 21px;
}

@media screen and (max-width: 1150px) {
  .mfa__navbar-links_container {
    display: none;
  }

  .mfa__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .mfa__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .mfa__navbar {
    padding: 2rem;
  }

  .mfa__navbar-sign {
    display: none;
  }

  .mfa__navbar-menu_container {
    top: 20px;
  }

  .mfa__navbar-menu_container-links-sign {
    display: block;
  }

  .mfa__navbar-links_logo img {
    width: 185px;
    height: 55px;
  }
}

@media screen and (max-width: 300px) {
  .mfa__navbar-links_logo img {
    width: 155px;
    height: 40px;
  }
}
