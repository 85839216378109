* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "futura-pt", sans-serif;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 79% 65%,
    rgba(58, 224, 128, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 79% 65%,
    rgba(58, 224, 128, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 79% 65%,
    rgba(58, 224, 128, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 79% 65%,
    rgba(58, 224, 128, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 79% 65%,
    rgba(58, 224, 128, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 7rem 8rem;
}

.section__padding-features {

  @media screen and (max-width: 768px) {
    padding: 8rem 4rem;
  }
}

.section__padding-footer {
  padding: 7rem 8rem;

}

.section__margin {
  margin: 5rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 1024px) {
  .gradient__bg {
    /* ff 3.6+ */
    background: -moz-radial-gradient(
      circle at 50% 91%,
      rgba(58, 224, 128, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(
      circle at 50% 91%,
      rgba(58, 224, 128, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );

    /* opera 11.10+ */
    background: -o-radial-gradient(
      circle at 50% 91%,
      rgba(58, 224, 128, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );

    /* ie 10+ */
    background: -ms-radial-gradient(
      circle at 50% 91%,
      rgba(58, 224, 128, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );

    /* global 92%+ browsers support */
    background: radial-gradient(
      circle at 50% 91%,
      rgba(58, 224, 128, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
}

@media screen and (max-width: 1024px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
  .section__padding-footer {
    padding: 3rem 3rem;
  }
}

@media screen and (max-width: 768px) {
  .section__padding {
    padding: 3rem;
  }
  .section__margin {
    margin: 4rem;
  }
  .section__padding-footer {
    padding: 3rem 2rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 3rem 1.6rem;
  }
  .section__margin {
    margin: 4rem 1.5rem;
  }
  .section__padding-footer {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 425px) {
  .section__padding {
    padding: 3rem 1.5rem;
  }
  .section__margin {
    margin: 4rem 1.5rem;
  }
}

/*-----------------NAVBAR-----------------*/

/*-----------------HERO-----------------*/

/*-----------------FOOTER-----------------*/

.Footer__Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 375px) {
  .section__padding-footer {
    padding: 1rem 1rem;
  }
}

/*-----------------COLOR PALETTE-----------------*/

//Titles #fff
//Accent/Highlights #3AE080
//Main Background #222629
//Contrasted background #474b4f
//Text color #6b6e70
