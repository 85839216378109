.mfa__whatmfa {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 2px solid var(--color-footer);
  box-shadow: 0px 8px 15px rgba(58, 224, 128, 0.3);
  border-radius: 5px;

  @media screen and (max-width: 425px) {
    padding: 1rem;
  }
}

.mfa__whatmfa-title {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  max-width: 500px;
  color: white;
}

.mfa__whatmfa-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mfa__whatmfa-feature .mfa__features-container__feature {
  margin: 0;
}

.mfa__whatmfa-feature .mfa__features-container__feature-text {
  max-width: 750px;
}

.mfa__whatmfa-heading {
  display: flex;
  justify-content: flex-start;
  margin: 4rem 0 2rem;
}

.mfa__whatmfa-heading h1 {
  font-family: var(--font-family);
  font-size: 34px;
  font-weight: 800;
  line-height: 45px;
  max-width: 510px;
  text-align: center;
}

.mfa__whatmfa-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-text);
  cursor: pointer;
}

.mfa__whatmfa-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 2rem;
}

/* Customizing feature component as per needs */
.mfa__whatmfa-container .mfa__features-container__feature {
  flex: 1;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}

/* Customizing feature component as per needs */
.mfa__whatmfa-container .mfa__features-container_feature-text {
  margin: 2rem 0;
}

@media screen and (max-width: 850px) {
  .mfa__whatmfa-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  .mfa__whatmfa-heading p {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  /* Customizing feature component as per needs */
  .mfa__whatmfa-feature .mfa__features-container__feature {
    flex-direction: column;
  }

  /* Customizing feature component as per needs */
  .mfa__whatmfa-feature .mfa__features-container_feature-text {
    margin-top: 0.5rem;
  }

  .mfa__whatmfa-heading h1 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media screen and (max-width: 350px) {
  /* Customizing feature component as per needs */
  .mfa__whatmfa-container .mfa__features-container__feature {
    margin: 1rem 0;
    min-width: 100%;
  }

  .mfa__whatmfa-heading h1 {
    font-size: 23px;
    line-height: 30px;
  }

  .mfa__whatmfa {
    padding: 1rem;
  }
}
