.mfa__Shipping-text-group {
  margin: 15px;
  h3,
  p {
    color: white;
  }
}

.mfa__Shipping-container {
  color: white;
}

.mfa__Shipping-title {
  text-align: center;
  margin-bottom: 1rem;
}
