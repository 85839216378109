.form-container {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);

  height: 650px;
  display: flex;
  flex-direction: row;
  width: auto;
}

.MuiDialog-paper {
  background: none !important;
  width: auto !important;
  height: auto !important;
}

.close-btn {
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 1.5rem;
  z-index: 1;
  color: #fff;
  cursor: pointer;
}

.form-content-left {
  background: var(--gradient-bar);
  border-radius: 3px 0 0 3px;
  display: flex;
  justify-content: center;
  width: auto;

  img {
    display: flex;
    width: 433px;
    height: 650px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.form-img-2 {
  display: flex;
  justify-self: center;
  align-self: center;
  overflow: hidden;
  max-width: 600px;
  img {
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 535px) {
    height: 50%;
  }

  @media screen and (max-width: 375px) {
    height: 40%;
  }

  @media screen and (max-width: 300px) {
    height: 30%;
  }
}

.form-success {
  text-align: center;
  font-size: 24px;
  padding-top: 20px;
  color: #fff;

  @media screen and (max-width: 535px) {
    margin-top: 0;
  }
}

.form-content-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 425px) {
    img {
      width: 70%;
    }
  }
}

.form-content-right {
  display: flex;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
}

.form-content-right-success {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);

  p {
    color: #fff;
    padding-top: 0.5rem;
    text-align: center;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-success-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  
  @media screen and (max-width: 1440px) {
    margin-left: 1rem;
  }
}

.form-success-text-container .form-success-discord {
  padding-top: 25px;
  color: #fff;
}

.form h1 {
  font-size: 1rem;
  text-align: start;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;

  @media screen and (max-width: 320px) {
    font-size: 0.8rem;
  }
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 80%;
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
}

.form-label {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: #fff;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  border: none;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input-btn {
  width: 80%;
  height: 50px;
  margin-top: 10px;
  border-radius: 5px;
  background: var(--gradient-bar);
  outline: none;
  border: none;
  color: #000;
  font-size: 20px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.form-input-btn:hover {
  cursor: pointer;
  background: var(--gradient-bar-reversed);
  transition: all 0.4s ease-out;
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
  margin-bottom: 15px;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}

$darkNavy: #213140;
$charcoal: #555555;
$gold: #b6985a;

$activeShadow: 0 0 10px rgba(var(--color-main), 0.5);

/* MIXINS */
@mixin hideInput {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
@mixin breakpoint($point) {
  @if $point == 1100 {
    @media (max-width: 1100px) {
      @content;
    }
  } @else if $point == 800 {
    @media (max-width: 800px) {
      @content;
    }
  }
}

/* TOGGLE STYLING */
.toggle {
  margin-bottom: 0.5rem;
  width: 80%;
  input {
    @include hideInput;
  }
  input + label {
    padding: 0.55rem 1rem;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: solid 1px #ddd;
    background-color: transparent;
    font-size: 1rem;
    line-height: 140%;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transition: border-color 0.15s ease-out, color 0.25s ease-out,
      background-color 0.15s ease-out, box-shadow 0.15s ease-out;
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    &:hover {
      cursor: pointer;
    }

    &:first-of-type {
      border-radius: 6px 0 0 6px;
      border-right: none;
    }
    &:last-of-type {
      border-radius: 0 6px 6px 0;
      border-left: none;
    }
  }

  input:checked + label {
    background-color: var(--color-main);
    color: #000;
    box-shadow: $activeShadow;
    border-color: var(--color-main);
    z-index: 1;
  }

  @include breakpoint(800) {
    input + label {
      padding: 0.75rem 0.25rem;
      flex: 0 0 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.form-radio-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  border: none;
  margin: 5px;
}

.toggle-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.form-success-joinbutton {
  margin-top: 15px;
  background-color: var(--color-main);
  padding: 0.5rem 1rem;
  color: #000;
  background: #3ae080;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}
