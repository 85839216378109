.mfa__Signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mfa__Signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  color: white;
  height: auto;
  padding: 1rem;
  border: 2px solid var(--color-main);
  box-shadow: 0px 8px 15px rgba(58, 224, 128, 0.3);

  @media screen and (min-width: 768px) {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
  }

  @media screen and (min-width: 1440px) {
    width: 50%;
  }

  @media screen and (min-width: 1980px) {
    width: 50%;
  }
}

.mfa__Signup-container h1 {
  text-align: center;
}

// .mfa__Signup-container button {
//   flex: 0.6;
//   width: 100%;
//   min-height: 50px;
//   background: #3ae080;
//   font-family: var(--font-family);
//   font-weight: bold;
//   font-size: 20px;
//   line-height: 27px;
//   color: #000;
//   cursor: pointer;
//   outline: none;
//   border: none;
//   padding: 0 1rem;
//   border-radius: 5px 0px 0px 5px;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
// }

.radio-container {
  display: flex;
  flex-direction: row;
}

.radio-group {
  position: relative;
  width: 100%;
  min-height: 50px;
  background: none;
  border-radius: 5px 0px 0px 5px;
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.radio-group1 {
  position: relative;
  width: 100%;
  min-height: 50px;
  background: none;
  border-radius: 5px 0px 0px 5px;
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
}

.form-input-label-radio {
  position: absolute;
  top: 10px;
  left: 35px;
  color: grey;
  font-size: 16px;
  font-weight: normal;
  pointer-events: none;
  transition: 300ms ease all;
}

.radio-group input {
  position: relative;
  top: 10px;
}

input[type="radio"] {
  height: 15px;
  width: 15px;
  vertical-align: middle;
}
