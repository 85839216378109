.mfa__showcase {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}

.mfa__showcase-heading {
  width: 100%;
  text-align: left;
  margin-bottom: 5rem;
}

.mfa__showcase-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 85px;
  letter-spacing: -0.04em;
  text-align: center;
}

.mfa__showcase-container {
  overflow: hidden;
}

.mfa__showcase-container_groupB {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 6rem;
}

@media screen and (max-width: 1024px) {
  .mfa__showcase-container_groupB {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
  }
}

@media screen and (max-width: 990px) {
  .mfa__showcase-container {
    flex-direction: column-reverse;
  }

  .mfa__showcase-container_groupA {
    margin: 2rem 0;
  }

  .mfa__showcase-container_groupA .mfa__showcase-container_article {
    width: 48%;
  }

  .mfa__showcase-container_groupA .mfa__showcase-container_article-image {
    height: 250px;
  }
}

@media screen and (max-width: 768px) {
  .mfa__showcase-container_groupB {
    grid-gap: 2rem;
  }

  .mfa__showcase-heading h1 {
    font-size: 56px;
    line-height: 80px;
  }
}

@media screen and (max-width: 700px) {
  .mfa__showcase-container_groupB {
    grid-template-columns: repeat(1, 1fr);
  }

  .mfa__showcase-container_groupA .mfa__showcase-container_article {
    width: 100%;
  }

  .mfa__showcase-heading h1 {
    font-size: 46px;
    line-height: 60px;
  }
}

@media screen and (max-width: 550px) {
  .mfa__showcase-heading h1 {
    font-size: 32px;
    line-height: 46px;
  }
}
