.mfa__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #3ae080, $alpha: 0.4);
}

.mfa__footer-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

.mfa__footer-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  text-align: center;
  letter-spacing: -0.04em;
}

.mfa__footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #fff;
  text-align: center;
  margin-bottom: 3rem;
  cursor: pointer;
}

.mfa__footer-btn p {
  box-sizing: border-box;
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  word-spacing: 2px;
}

.mfa__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  text-align: left;
  color: white;
}


.mfa__footer-links div {
  margin: 1rem;
}

.mfa__footer-links_logo {
  display: flex;
  flex-direction: column;
}

.mfa__footer-links_logo img {
  width: 200px;
  height: 50px;
  margin-bottom: 1rem;
}

.mfa__footer-links_logo p {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.mfa__footer-links_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.mfa__footer-links_div h4 {
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 1rem;
}

.mfa__footer-links_div a,
.mfa__footer-links_div p {
  font-family: var(--font-family);
  color: #fff;
  font-size: 14px;
  line-height: 8px;
  margin: 0.5rem 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.mfa__footer-links_div a:hover,
.mfa__footer-links_div p:hover {
  color: var(--color-main);
}

.mfa__footer-copyright {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

.mfa__footer-copyright p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}

.mfa__footer-copyright_legal a {
  font-size: 10px;
}

.mfa__footer-sociallinks-container {
  list-style: none;
  margin-bottom: 1rem;
}

.mfa__footer-sociallinks-link {
  display: inline-block;
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--color-main);
  }
}

@media screen and (max-width: 850px) {
  .mfa__footer-heading h1 {
    font-size: 44px;
    line-height: 50px;
  }
}

@media screen and (max-width: 550px) {
  .mfa__footer-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .mfa__footer-links div {
    margin: 1rem 0;
  }

  .mfa__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }

  .mfa__footer-links_logo img {
    width: 180px;
    height: 50px;
    margin-bottom: 1rem;
  }

  .mfa__footer-links {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 350px) {
  .mfa__footer-heading h1 {
    font-size: 24px;
    line-height: 34px;
  }

  .mfa__footer-links_div p {
    font-size: 11px;
    line-height: 5px;
  }

  .mfa__footer-links_logo img {
    width: 150px;
    height: 50px;
    margin-bottom: 1rem;
  }
}
