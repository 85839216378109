// =========================
// Global
// =========================

.visuallyhidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

// =========================
// Icons
// =========================

.icon {
  fill: #fff;
  width: 100%;
}

// =========================
// Buttons
// =========================

.btn {
  padding: 0.5rem 1rem;
  color: #000;
  background: #3ae080;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;

  //   &:focus {
  //     outline-color: var(--color-main);
  //     outline-offset: 2px;
  //     outline-style: solid;
  //     outline-width: 3px;
  //   }

  //   &:active {
  //     transform: translateY(1px);
  //   }

  @media screen and (max-width: 425px) {
    font-size: 22px;
    line-height: 26px;
  }
}

.carousel-slide__action {
  box-shadow: 0px 8px 15px rgba(58, 224, 128, 0.3);
}

// =========================
// Slider controls
// =========================

.carousel-slider__controls {
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100% + 2rem);
  width: 100%;

  .btn {
    align-items: center;
    background-color: transparent;
    border: 3px solid transparent;
    border-radius: 100%;
    display: flex;
    height: 3rem;
    padding: 0;
    width: 3rem;

    &:focus {
      border-color: var(--color-main);
      outline: none;
    }

    &--previous > * {
      transform: rotate(180deg);
    }
  }
}

// =========================
// Slider
// =========================

.creator-carousel-slider {
  height: 60vmin;
  margin: 0 auto;
  position: relative;
  width: 50vmin;
  padding-top: 1rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 400px) {
    height: 75vmin;
  }
}

.carousel-slider__wrapper {
  display: flex;
  flex-direction: row;
  margin: 0 calc(4vmin * -1);
  position: absolute;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}

// =========================
// Slide
// =========================

.carousel-slide {
  align-items: center;
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 60vmin;
  justify-content: center;
  margin: 0 4vmin;
  opacity: 0.25;
  position: relative;
  text-align: center;
  transition: opacity calc(var(--base-duration) / 2) var(--base-ease),
    transform calc(var(--base-duration) / 2) var(--base-ease);
  width: 50vmin;
  z-index: 1;

  &--previous,
  &--next {
    &:hover {
      opacity: 0.5;
    }
  }

  &--previous {
    cursor: pointer;

    &:hover {
      transform: translateX(2%);
    }
  }

  &--next {
    cursor: pointer;

    &:hover {
      transform: translateX(-2%);
    }
  }

  @media screen and (max-width: 400px) {
    height: 75vmin;
  }
}

.carousel-slide--current {
  opacity: 1;
  pointer-events: auto;
  user-select: auto;

  @media (hover: hover) {
    &:hover .carousel-slide__image-wrapper {
      transform: scale(1.025)
        translate(
          calc(var(--x) / var(--d) * 1px),
          calc(var(--y) / var(--d) * 1px)
        );
    }
  }
}

.carousel-slide__image-wrapper {
  background-color: rgba(53, 206, 117, 0.1);
  border-radius: 1%;
  overflow: hidden;
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  border: 2px solid #3ae080;
  box-shadow: 0px 8px 15px rgba(58, 224, 128, 0.3);
  border-radius: 50%;

  @media screen and (max-width: 632px) {
    height: 100%;
		width: auto;
  }
}

.carousel-slide__image {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--base-duration) var(--base-ease),
    transform var(--base-duration) var(--base-ease);
  user-select: none;
  display: flex;

  @media (hover: hover) {
    .carousel-slide--current & {
      transform: translate(
        calc(var(--x) / var(--d) * 1px),
        calc(var(--y) / var(--d) * 1px)
      );
    }
  }

  @media screen and (max-width: 632px) {
    height: 100%;
    width: 100%;
  }
}

.carousel-slide__headline {
  font-size: 5vmin;
  font-weight: 600;
  display: flex;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 4vmin;
  }
}

.carousel-slide__content {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  transition: transform var(--base-duration) var(--base-ease);
  visibility: hidden;
  justify-content: center;
  align-items: center;

  .carousel-slide--current & {
    animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
    visibility: visible;

    @media (hover: hover) {
      transform: translate(
        calc(var(--x) / var(--d) * -1px),
        calc(var(--y) / var(--d) * -1px)
      );
    }
  }

  > * + * {
    margin-top: 0.5rem;
  }
}

// =========================
// Animations
// =========================

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
