.mfa__multiHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mfa__multiHeader-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mfa__multiHeader-title_subtext {
  font-family: var(--font-family);
  font-weight: 700 !important;
  font-size: 82px;
  line-height: 0.85em;
  text-transform: uppercase;
  letter-spacing: -0.04em;
  max-width: 600px;
  color: #fff;
}

.mfa__multiHeader-content h1 {
  font-family: var(--font-family);
  font-size: 55px;
  line-height: 0.85em;
  text-transform: uppercase;
  letter-spacing: -0.04em;
  max-width: 600px;
}

.mfa__multiHeader-content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: lighter;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-text);
  margin-top: 2rem;
  max-width: 800px;
}

.mfa__multiHeader-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
}

.mfa__multiHeader-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  background: none;
  border-radius: 5px 0px 0px 5px;
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}
::placeholder {
  color: var(--color-text);
}
.mfa__multiHeader-content__input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  background: #3ae080;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #000;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0 1rem;
  border-radius: 5px 0px 0px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.mfa__multiHeader-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}

.mfa__multiHeader-content__people img {
  width: 181.79px;
  height: 38px;
}

.mfa__multiHeader-content__people p {
  margin-left: 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: var(--color-text);
  text-align: center;
  margin: 0 0 0 1rem;
}

.mfa__multiHeader-image {
  flex: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mfa__multiHeader-image img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1150px) {
  .mfa__multiHeader {
    flex-direction: column;
  }

  .mfa__multiHeader-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .mfa__multiHeader h1 {
    font-size: 62px;
    line-height: 52px;
  }

  .mfa__multiHeader p {
    font-size: 16px;
    line-height: 24px;
  }

  .mfa__multiHeader-content__people {
    flex-direction: column;
  }

  .mfa__multiHeader-content__people p {
    margin: 0;
  }

  .mfa__multiHeader-content__input input,
  .mfa__multiHeader-content__input button {
    font-size: 16px;
    line-height: 24px;
  }

  .mfa__multiHeader-content h2 {
    font-size: 55px;
  }
}

@media screen and (max-width: 490px) {
  .mfa__multiHeader h1 {
    font-size: 45px;
    line-height: 39px;
  }

  .mfa__multiHeader-content h2 {
    font-size: 38px;
  }

  .mfa__multiHeader p {
    font-size: 14px;
    line-height: 24px;
  }

  .mfa__multiHeader-content__input input,
  .mfa__multiHeader-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width: 300px) {
  .mfa__multiHeader h1 {
    font-size: 40px;
    line-height: 30px;
  }

  .mfa__multiHeader-content {
    max-width: 500px;
  }

  .mfa__multiHeader-content h2 {
    font-size: 32px;
  }

  .mfa__multiHeader p {
    font-size: 14px;
    line-height: 24px;
  }

  .mfa__multiHeader-content__input input,
  .mfa__multiHeader-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}
