.mfa__cta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border: 2px solid var(--color-main);
  // background-color: rgba($color: #3ae080, $alpha: 0.4);
  box-shadow: 0px 8px 15px rgba(58, 224, 128, 0.3);
  border-radius: 5px;
}

.mfa__cta-bar {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.95);
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

.mfa__cta-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mfa__cta-content p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-text);
  text-align: left;
  margin-left: 1rem;

  @media screen and (max-width: 320px) {
    font-size: 15px;
    line-height: 20px;
  }
}

.mfa__cta-content h3 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 31px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 1rem;
  margin-left: 1rem;
  text-align: left;
}

.mfa__cta-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.mfa__cta-btn button {
  background: var(--color-main);
  border-radius: 40px;
  color: #000;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
}

.mfa__cta-video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mfa__cta-video video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
}

@media screen and (max-width: 650px) {
  .mfa__cta {
    flex-direction: column;
  }

  .mfa__cta-btn {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 550px) {
  .mfa__cta-content h3 {
    font-size: 20px;
    line-height: 25px;
    margin: 1rem 0;
  }

  .mfa__cta-btn button {
    font-size: 14px;
    line-height: 28px;
  }

  .mfa__cta-video video {
    padding: 0;
  }

  .mfa__cta-bar {
    margin-left: 0;
  }

  .mfa__cta-content p {
    margin: 1rem 0;
  }
}
