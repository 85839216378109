.mfa__Privacy-container {
  color: white;
}

.mfa__Privacy-text-group {
  margin: 15px;
  h3,
  p {
    color: white;
  }
  ul {
    padding-left: 1rem;
  }
}

.mfa__Privacy-title {
  text-align: center;
  margin-bottom: 1rem;
}
