@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "futura-pt", sans-serif;

  // --gradient-text: linear-gradient(to bottom, #3b4a3f, #426d4f, #45925f, #43b870, #3ae080);
  --gradient-text: #fff;
  --gradient-bar: linear-gradient(103.22deg, #007a33 -9.86%, #3ae080 95.55%);
  --gradient-bar-reversed: linear-gradient(
    103.22deg,
    #3ae080 -9.86%,
    #007a33 95.55%
  );
  --color-bg: #000;
  --color-footer: #3ae080;
  --color-blog: #042c54;
  --color-text: rgb(221, 221, 221);
  --color-subtext: #000;
  --color-main: #3ae080;

  --color-primary: #6b7a8f;
  --color-secondary: #101118;
  --color-accent: #1d1f2f;
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);

  --x: 0;
  --y: 0;
  --d: 25;
}
