.mfa__possibility {
  display: flex;
  flex-direction: row;
}

.mfa__possibility-image {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.mfa__possibility-image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.mfa__possibility-content {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 1rem;

  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
}

.mfa__possibility-content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-main);
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.03);
  }

  @media screen and (max-width: 320px) {
    font-size: 15px;
  }
}

.mfa__possibility-content h4:last-child {
  color: var(--color-main);
  text-decoration: underline 1px var(--color-main);
  text-underline-offset: 1.5px;
}
.mfa__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
}
.mfa__possibility-content p {
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 24px;
  color: var(--color-text);
  margin-bottom: 2rem;

  @media screen and (max-width: 375px) {
    font-size: 16px;
    line-height: 23px;
  }

  @media screen and (max-width: 320px) {
    font-size: 15px;
    line-height: 20px;
  }
}

@media screen and (max-width: 950px) {
  .mfa__possibility {
    flex-direction: column;
  }

  .mfa__possibility-image {
    margin: 1rem 0;
  }

  .mfa__possibility-content {
    margin-top: 1rem;
  }
}
